<template>
  <v-card :class="[{borderColor: success}]" class="elevation-16" width="auto" outlined>
    <v-card-title class="text-h6 textAlign">
      <!-- <v-icon v-bind:class="[{iconColor : success}, {}]" class="mr-1">mdi-playlist-check</v-icon> -->
      {{ itemContent.itemQuestionLabel }}
    </v-card-title>
    <v-card-text>
      <div class="text-center mt-3">
        <v-rating
          v-model="itemContent.answer"
          color="yellow darken-3"
          background-color="grey darken-1"
          empty-icon="$ratingFull"
          hover
          large
          @input="updateInput($event, itemContent.questionCode)"
        ></v-rating>
        {{ itemContent.itemText }}
      </div>
    </v-card-text>
  </v-card>
</template>


<script>
export default {
  name: "Rating",
  props: {
    itemContent: Object,
  },
  data() {
    return {
      answer: 0,
      questionCode: "",
      rating: "",
      success: false
    };
  },
  methods: {
    updateInput: function (value, questionCode) {
      let data = { rating: value, questionCode: questionCode };
      this.$emit("input", data);
      this.success = true
    },
  },
};
</script>

<style scoped>
.borderColor {
  border-top: 2px solid green;
}
.iconColor {
  color: green;
}

.textAlign {
  text-align: center;
  word-break: break-word;
}
</style>