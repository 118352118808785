<template>
  <div>
    <p class="headline text-center text-decoration-underline"> {{brandName}} </p>
    <v-container>
      <v-row v-for="(question, index) in questions" :key="index">
        <v-col>
          <Rating
            @input="response"
            :itemContent="question.itemContent"
            v-if="question.type == 'rating'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center" sm-12 md-12 lg-12>
          <v-btn @click="submitResponse" :disabled="disable" class="primary">{{
            submit
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ReviewDataService } from "../../services/DataServiceiReview";
import Rating from "./components/rating.vue";
const reviewDataService = new ReviewDataService();

export default {
  components: { Rating },
  data() {
    return {
      type: "",
      questions: [],
      submit: "Submit",
      value: "",
      responseArray: [],
      disable: true,
      brandName: ''
    };
  },

  mounted() {
    this.startReview();
    this.getBrandName();
  },

  methods: {
    getBrandName() {
      let dataToSend = {
        subcategoryId: this.$route.params.data.subCategoryId,
        brandId: this.$route.params.data.brandId
      }
      reviewDataService.getBrandName(dataToSend).then(res => {
        this.brandName = res.brandName
      })
    },

    startReview() {
      reviewDataService.startReview({ reviewCode: this.$route.params.reviewCode }).then((res) => {
        this.questions = res.questions;
      });
    },

    submitResponse: function () {
       let dataToSend = {
         answers: this.responseArray,
         segmentId:  this.$route.params.data.segmentId,
         categoryId: this.$route.params.data.categoryId,
         subCategoryId: this.$route.params.data.subCategoryId,
         brandId: this.$route.params.data.brandId,
         userId: this.$route.params.userId
       }
       this.$store.state.loader = true
       reviewDataService.saveResponse(dataToSend).then(() => {
          this.$store.state.loader = false
          this.$router.push({ name: 'reviewSuccess' })
       }).catch(error => {
          this.$store.state.loader = false
         console.log(error)
       })
    },
    
    response(value) {
      this.value = value;
      if (this.responseArray.length > 0) {
        for (var i = 0; i < this.responseArray.length; i++) {
          if (this.responseArray[i].questionCode == value.questionCode) {
            this.responseArray.splice(i, 1);
          }
        }
      }
      this.responseArray.push(value);
      if (this.questions.length === this.responseArray.length) {
        this.disable = false;
      }
    },
  },
};
</script>